<template>
  <div class="dashboard-content">
    <div class="row">
       <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
           <div class="row">
             <div class="col-md-6">
              <h6 class="mb-2" style="text-transform:uppercase;display:inline !important">
                Checkout
              </h6>
             </div>
             <div class="col-md-6">
               
              <h6 class="font-weight-bold " style="float:right;display:inline !important">  <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
 | 1 Item</h6> 
             </div>
           </div>
            <hr>
            <div class="row container">
                <div class="col-md-8 table-responsive">
                <table class="table table-borderless " style="border:none">
                  <tr>
                    <th style="color:#007bff"> ITEMS </th>
                    <th style="color:#007bff"> PRICE </th>
                  </tr>
                  <tr>
                    <td style="color:#4d4d4d" class="font-weight-bold"> <h5>77007 ZIP CODE</h5> </td>
                    <td style="color:#4d4d4d"> <h4>$5</h4> </td>
                  </tr>
                </table>
                </div>
            </div>
            <div class="row">
              <div class="col-md-3">
               <h5 style="color:#4d4d4d">Payment Method</h5>
                <img src="/static/images/visa.png"  style="width:120px;height:100px" alt="">
              </div>
              </div>
            <div class="row">
                <div class=col-md-6>
                    <router-link to="/create-child-ad" class="btn btn-success mt-3"><i class="fas fa-arrow-left"></i> Back</router-link>
                </div>
                <div class=col-md-6>
                    <router-link to="/profile" class="btn btn-primary mt-3" style="float:right"> <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>  &nbsp; Place Order  </router-link>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="copyrights">
          © 2021 Thriggle. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axios} from '../../../../services/http-common';
export default {
}
</script>
